import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["release"];

  normalizeDate(date) {
    if (!date || isNaN(new Date(date))) {
      return null;
    }
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);
    return normalizedDate;
  }

  connect() {
    const currentDate = this.normalizeDate(new Date());
    const releases = this.releaseTargets.map((release) => ({
      element: release,
      date: this.normalizeDate(release.getAttribute("publish-date"))
    }));

    // Find hot off the press resources that match today's date and filter out nil dates
    const validReleases = releases.filter(({ date }) => date !== null); 
    const todaysReleases = validReleases.filter(
      ({ date }) => date.valueOf() === currentDate.valueOf()
    );

    // Find the most recent release (either today or from the past)
    const releaseToShow = todaysReleases.length > 0
      ? todaysReleases[todaysReleases.length - 1]
      : validReleases
          .filter(({ date }) => +date < +currentDate)
          .reduce((latest, current) =>
            +current.date > +latest.date ? current : latest,
            { date: new Date(0) }
          );

    if (releaseToShow?.element) {
      releaseToShow.element.classList.remove("hidden");
    }
  }
}
